import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VideogPlayer from 'videog-player';

const videoTheme = {
  palette: {
    background: 'rgb(0, 0, 0)',
    // primary: 'rgb(203, 0, 68)',
    controls: {
      background: 'rgb(245, 245, 245)',
      contrastText: 'rgb(62, 61, 64)',
    },
    progress: {
      primary: 'rgb(203, 0, 68)',
      background: 'rgb(224, 225, 221)',
      buffer: 'rgb(100, 99, 102)',
      over: 'rgb(100, 99, 102)',
    },
    tooltip: {
      background: 'rgb(100, 99, 102)',
      contrastText: 'rgb(250, 250, 250)',
    },
  },
};

const VideoPage = () => (
  <div>
    <Card>
      <CardContent>
        <div
          style={{
            fontSize: '24px',
          }}
        >
          <span
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: 'rgb(55, 65, 112)',
            }}
          >
            Valorisons et partageons toutes les bonnes idées !
            <br />
            &nbsp;
            <br />
          </span>
          <span
            style={{
              fontSize: '16px',
              /* color: 'rgb(55, 65, 112)', */
            }}
          >
            Les Victoires du Projet EOLE ont pour objectif de mettre à l’honneur
            et de partager les bonnes pratiques mises en œuvre par les
            entreprises partenaires en faveur de la sécurité et du développement
            durable.
            <br />
            &nbsp;
          </span>
        </div>
        <VideogPlayer
          id="5b2827ef3ef7ac4adaca0826fb92ddbb05b6f4c4"
          poster={3}
          autoplay={false}
          autoplayOnLandscape={false} /* not when multiple videos */
          theme={videoTheme}
        />
        <div
          style={{
            fontSize: '16px',
          }}
        >
          <span
            style={{
              fontSize: '14px',
              /* color: 'rgb(55, 65, 112)', */
            }}
          >
            <br />
            Xavier Gruz, Directeur du Projet EOLE<br />
            &nbsp;
          </span>
          <hr />
          <br />
        </div>
        <div>
          <span
            style={{
              fontSize: '16px',
              backgroundColor: 'rgb(213, 129, 176)',
              color: 'white',
              padding: '10px',
            }}
          >
            <a
              style={{
                color: 'white',
              }}
              href="#/program"
            >
              LES VICTOIRES
            </a>
          </span>
        </div>
        <br />
        <br />
        <div>
          <br />
          Pour toute question :{' '}
          <a href="mailto:victoires.eole@phileogeleven.com">
            écrivez-nous
          </a>
        </div>
      </CardContent>
    </Card>
  </div>
);

export default VideoPage;
